<template>
    <v-card>
        <v-toolbar color="grey darken-3" dark elevation="1">
            <v-toolbar-title>
                {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu offset-y v-model="menu">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            class="grey darken-3"
                            icon
                            outlined
                            :disabled="isNew"
                            v-bind="attrs"
                            v-on="on"
                            :loading="getModalLoading"
                            @click="menu = true"
                    >
                        <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
                    </v-btn>
                </template>
                <v-list dense rounded nav>
                    <v-list-item link class="px-4" @click.stop="deleteDialog">
                        <v-list-item-icon>
                            <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-toolbar>
        <v-card-text :class="isNew ? 'px-4 pt-1' : 'pt-4'">
            <v-row v-if="isNew">
                <v-col cols="12" class="px-2">
                    <v-tabs class="custom-tabs without" color="success" v-model="step">
                        <v-tab class="text-left justify-start">
                            Персональні дані
                        </v-tab>
                        <v-tab class="text-left justify-start">
                            Трудовий договір
                        </v-tab>
                        <v-tab class="text-left justify-start">
                            Договір ЦПХ
                        </v-tab>
                        <v-tab class="text-left justify-start">
                            Інше
                        </v-tab>

                        <v-tab-item class="pa-0">
                            <div class="form-title">Персональні дані працівника</div>
                            <v-form ref="personalInfo" v-model="personalInfoValid">
                                <v-row class="mt-0 px-3">
                                    <v-col cols="12">
                                        <v-text-field type="text" filled label="Прізвище, ім’я та по-батькові"
                                                      v-model="full_name"
                                                      hide-details
                                                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                      :class="full_name ? '' : 'req-star'"
                                                      color="grey"
                                                      @change="fullNameChange"
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field type="text" filled label="Код за ДРФО" v-model="ipn"
                                                      hide-details
                                                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                      :class="ipn ? '' : 'req-star'"
                                                      color="grey"
                                                      @input="getDateOfBirth($event)"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <date-component v-model="date_of_birth" label="Дата народження"
                                                        :class_="date_of_birth ? '' : 'req-star'"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select v-model="sex_type" :items="sex_type_select" label="Стать"
                                                  required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                  hide-details filled
                                                  :class="sex_type ? '' : 'req-star'"
                                                  color="grey"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field type="text" filled label="Серія та № паспорта"
                                                      v-model="passport"
                                                      hide-details
                                                      color="grey"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select hide-details filled color="grey"
                                                  v-model="cripple_category"
                                                  :items="cripple_select"
                                                  label="Інвалідність"
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <ACC_ChartAccount
                                                :value="chart_of_account"
                                                :class="{'req-star': !chart_of_account}"
                                                label="Рахунок витрат"
                                                @autocompleteChange="chartOfAccountChange"
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-radio-group
                                                v-model="person_type"
                                                row
                                                hide-details
                                                class="mt-0"
                                                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                        >
                                            <v-radio
                                                    label="Штатний працівник"
                                                    value="full_time"
                                                    color="grey darken-1"
                                            />
                                            <v-radio
                                                    label="По дог. ЦПХ"
                                                    value="by_contract"
                                                    color="grey darken-1"
                                            />
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-tab-item>

                        <v-tab-item class="pa-0 px-2 pb-4">
                            <div class="form-title">Налаштування трудового договору</div>
                            <v-form ref="workPlace" v-model="workPlaceValid">
                                <v-row class="mt-0 px-3">
                                    <v-col cols="12">
                                        <v-radio-group
                                                v-model="full_time_type"
                                                row
                                                hide-details
                                                class="mt-0"
                                                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                        >
                                            <v-radio
                                                    label="Основне місце"
                                                    value="main_work_place"
                                                    color="grey darken-1"
                                            />
                                            <v-radio
                                                    label="Сумісництво"
                                                    value="combination"
                                                    color="grey darken-1"
                                            />
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols="12">
                                        <ACC_Subdivision
                                                :value="subdivision_id"
                                                :class="{'req-star': !subdivision_id}"
                                                @autocompleteChange="subdivisionChange"
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <ACC_Position
                                                :value="position_id"
                                                :class="{'req-star': !position_id}"
                                                @autocompleteChange="positionChange"
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <ACC_WorkSchedule
                                                :value="work_schedule_id"
                                                :class="{'req-star': !work_schedule_id}"
                                                @autocompleteChange="workScheduleChange"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field type="text"
                                                      filled label="Оклад"
                                                      v-model.number="contract_sum"
                                                      hide-details
                                                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                      :class="contract_sum ? '' : 'req-star'"
                                                      color="grey"
                                                      v-decimal/>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-radio-group
                                                v-model="pay_type"
                                                row
                                                hide-details
                                                class="mt-3"
                                                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                        >
                                            <v-radio
                                                    label="Оклад по днях"
                                                    value="pay_by_days"
                                                    color="grey darken-1"
                                            />
                                            <v-radio
                                                    label="Оклад по годинах"
                                                    value="pay_by_hours"
                                                    color="grey darken-1"
                                            />
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field type="text" min="0" max="2" step="0.25"
                                                      filled label="Кількість ставок"
                                                      v-model.number="rate_count"
                                                      hide-details
                                                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                      :class="rate_count ? '' : 'req-star'"
                                                      color="grey"
                                                      v-decimal
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <date-component v-model="hire_date"
                                                        label="Дата прийняття"
                                                        required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                        :class_="hire_date ? '' : 'req-star'"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-checkbox v-model="new_work_place"
                                                    hide-details
                                                    color="grey"
                                                    label="Нове робоче місце"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <date-component v-model="new_work_place_date"
                                                        label="Дата ств.нового роб.місця"
                                                        :disabled="!new_work_place"
                                        />
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-tab-item>

                        <v-tab-item class="pa-0 px-2 pb-4">
                            <div class="form-title">Налаштування договору ЦПХ</div>
                            <v-form ref="contractForm" v-model="contractFormValid">
                                <v-row class="mt-0 px-3">
                                    <v-col cols="12">
                                        <ACC_Subdivision
                                                :value="subdivision_id"
                                                :class="{'req-star': !subdivision_id}"
                                                @autocompleteChange="subdivisionChange"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <date-component v-model="contract_date_start"
                                                        label="Період з"
                                                        :class_="contract_date_start ? '' : 'req-star'"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <date-component v-model="contract_date_end"
                                                        label="по"
                                                        :class_="contract_date_end ? '' : 'req-star'"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select hide-details filled color="grey"
                                                  v-model="contract_pay_type"
                                                  :items="contract_pay_type_select"
                                                  label="Вид оплати"
                                                  required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                  :class="contract_pay_type ? '' : 'req-star'"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field type="text"
                                                      filled label="Сума по дог."
                                                      v-model.number="contract_sum"
                                                      hide-details
                                                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                      :class="contract_sum ? '' : 'req-star'"
                                                      color="grey"
                                                      v-decimal
                                        />
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-tab-item>


                        <v-tab-item class="pa-0 px-2 pb-4">
                            <div class="form-title">Інші відомості</div>
                            <v-form ref="otherForm" v-model="otherFormValid">
                                <v-row class="mt-0 px-3">
                                    <v-col cols="12">
                                        <v-select hide-details filled color="grey"
                                                  v-model="social_privilege"
                                                  :items="social_privilege_select"
                                                  label="Податкова соціальна пільга"/>
                                    </v-col>
                                    <v-col cols="12">
                                        <div class="form-title mb-3" style="font-size: .94rem">Діти</div>
                                        <v-row>
                                            <v-col cols="12">
                                                <div class="d-flex mb-4">
                                                    <v-btn small depressed color="success" class="mr-2"
                                                           @click="add_child">Додати
                                                    </v-btn>
                                                    <v-btn small depressed color="error" @click="remove_child">
                                                        Видалити
                                                    </v-btn>
                                                </div>
                                                <div class="table-header" style="display: flex;">
                                                    <div class="table-col" style="flex: 0 0 60px; padding-left: 5px">
                                                    </div>
                                                    <div class="table-col" style="flex: 0 0 90px;">
                                                        № п/п
                                                    </div>
                                                    <div class="table-col" style="flex: 1;">
                                                        Дата народження
                                                    </div>
                                                    <div class="table-col" style="flex: 0 0 120px;">
                                                        На утриманні
                                                    </div>
                                                </div>
                                                <div class="table-body">
                                                    <div class="table-row" style="display: flex"
                                                         v-for="(item, idx) in childs"
                                                         :key="idx"
                                                    >
                                                        <div class="table-col"
                                                             style="flex: 0 0 60px; padding-left: 5px">
                                                            <v-simple-checkbox
                                                                    :value="selected_row === item.row_num"
                                                                    :ripple="false"
                                                                    @input="changeSelectRow(item.row_num)"
                                                                    class="pa-0 ma-0"/>
                                                        </div>
                                                        <div class="table-col font-weight-bold"
                                                             style="flex: 0 0 90px; font-size: 1rem">
                                                            {{ idx + 1 }}
                                                        </div>
                                                        <div class="table-col" style="flex: 1;">
                                                            <date-component v-model="item.date_of_birth"
                                                                            :filled="false"
                                                                            class_="pt-0 mt-0"
                                                            />
                                                        </div>
                                                        <div class="table-col" style="flex: 0 0 120px;">
                                                            <v-simple-checkbox v-model="item.on_maintenance"
                                                                               class="pa-0 ma-0 ml-2 table-input"
                                                                               :ripple="false"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-tab-item>

                    </v-tabs>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" class="px-2">
                    <v-tabs class="custom-tabs" color="success" v-model="step">
                        <v-tab class="text-left justify-start">
                            Персональні дані
                        </v-tab>
                        <v-tab class="text-left justify-start">
                            Кадрові дані
                        </v-tab>

                        <v-tab-item class="pa-0">
                            <v-form ref="form" v-model="formValid">
                                <v-row class="mt-0 px-3">
                                    <v-col cols="12">
                                        <v-text-field type="text" filled label="Прізвище, ім’я та по-батькові"
                                                      v-model="full_name"
                                                      hide-details
                                                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                      :class="full_name ? '' : 'req-star'"
                                                      color="grey"
                                                      @change="fullNameChange"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field type="text" filled label="Код за ДРФО" v-model="ipn"
                                                      hide-details
                                                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                                      :class="ipn ? '' : 'req-star'"
                                                      color="grey"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field type="text" filled label="Серія та № паспорта" v-model="passport"
                                                      hide-details
                                                      color="grey"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <date-component v-model="date_of_birth" label="Дата народження"
                                                        :class_="date_of_birth ? '' : 'req-star'"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field type="text" filled label="Підрозділ" v-model="subdivision_name"
                                                      hide-details
                                                      disabled
                                                      color="grey"
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field type="text" filled label="Посада" v-model="position_name"
                                                      hide-details
                                                      disabled
                                                      color="grey"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-select type="text" filled label="Вид договору"
                                                  v-model="person_type"
                                                  :items="person_type_select"
                                                  hide-details
                                                  disabled
                                                  color="grey"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-select type="text" filled label="Вид зайнятості"
                                                  v-model="full_time_type"
                                                  :items="full_time_type_select"
                                                  hide-details
                                                  disabled
                                                  color="grey"
                                        />
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-tab-item>
                        <v-tab-item class="pa-0">
                            <v-row class="mt-1">
                                <v-col cols="12">
                                    <v-list dense flat class="py-0 transparent">
                                        <v-list-item
                                                v-for="(item, idx) in document_history"
                                                :key="idx"
                                                class="grey lighten-4 mb-1"
                                        >
                                            <v-list-item-icon class="mr-4">
                                                <v-icon class="mt-2 mr-2" size="30"
                                                        :color="document_type_color[item.doc_type]">
                                                    mdi-account-reactivate-outline
                                                </v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <div style="font-size: .94rem; font-weight: 500">{{
                                                    item.print_name
                                                    }}
                                                </div>
                                                <div class="mt-1">
                                                    <v-chip small label class="mr-2">{{
                                                        item.date_start | formatDate
                                                        }}
                                                    </v-chip>
                                                    <v-chip small label v-if="item.date_end">
                                                        {{ item.date_end | formatDate }}
                                                    </v-chip>
                                                </div>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-btn icon color="grey">
                                                    <v-icon color="secondary">mdi-information-outline</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions :class="isNew ? 'px-6' : ''">
            <template v-if="!isNew">
                <v-spacer></v-spacer>
                <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
                       class="button-accept">
                    <v-icon left>mdi-content-save</v-icon>
                    Зберегти
                </v-btn>
                <v-btn depressed text tile color="grey" @click="closeModal">
                    <v-icon left>mdi-close</v-icon>
                    Відмінити
                </v-btn>
            </template>
            <template v-else>
                <template v-if="step===0">
                    <v-btn class="mr-2" depressed width="140" @click.stop="closeModal">Закрити</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" depressed width="140" @click.stop="nextStep(0)">Далі</v-btn>
                </template>
                <template v-if="step===1">
                    <v-btn class="mr-2" depressed @click.stop="closeModal" width="120">Закрити</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="grey lighten-3" class="mr-2"
                           @click.stop="prevStep(1)"
                           depressed
                           width="120"
                    >Назад
                    </v-btn>
                    <v-btn color="secondary"
                           @click.stop="nextStep(1)"
                           width="120" depressed :loading="getModalLoading"
                    >Далі
                    </v-btn>
                </template>
                <template v-if="step===2">
                    <v-btn class="mr-2" depressed @click.stop="closeModal" width="120">Закрити</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="grey lighten-3" class="mr-2"
                           @click.stop="prevStep(2)"
                           depressed
                           width="120"
                    >Назад
                    </v-btn>
                    <v-btn color="secondary"
                           @click.stop="nextStep(2)"
                           width="120" depressed :loading="getModalLoading"
                    >Готово
                    </v-btn>
                </template>
                <template v-if="step===3">
                    <v-btn class="mr-2" depressed @click.stop="closeModal" width="120">Закрити</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="grey lighten-3" class="mr-2"
                           @click.stop="prevStep(3)"
                           depressed
                           width="120"
                    >Назад
                    </v-btn>
                    <v-btn color="secondary"
                           @click.stop="nextStep(3)"
                           width="120" depressed :loading="getModalLoading"
                    >Готово
                    </v-btn>
                </template>
            </template>
        </v-card-actions>
    </v-card>
</template>

<script>
import {
    CREATE_PERSONAL_LIST_ELEMENTS,
    UPDATE_PERSONAL_LIST_ELEMENTS,
    REMOVE_PERSONAL_LIST_ELEMENTS
} from "@/store/actions/accounting/list";
import {mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {sex_type_select, person_type_select, full_time_type_select} from "@/utils/accounting"
import {format_date} from "@/utils/icons"
import personalAPI from "@/utils/axios/accounting/personal"

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'personal_accounting_modal_delete'

export default {
    name: "HWP_Modal_Accounting_Personal",
    mixins: [ModalComponentMixin],
    components: {
        ACC_Position: () => import("@/components/accounting/autocomplite/modal/ACC_Position"),
        ACC_Subdivision: () => import("@/components/accounting/autocomplite/modal/ACC_Subdivision"),
        ACC_WorkSchedule: () => import("@/components/accounting/autocomplite/modal/ACC_Work_Schedule"),
        ACC_ChartAccount: () => import("@/components/accounting/autocomplite/modal/ACC_Chart_of_account")
    },
    data() {
        return {
            document_type_select: {
                'contract_by_cln': 'Договір цивільно-правового харектеру',
                'employment': 'Прийом на роботу',
            },
            document_type_color: {
                'contract_by_cln': 'primary',
                'employment': 'success',
            },
            selected_row: -1,
            childs: [],
            document_history: [],
            social_privilege_select: [
                {text: 'Базова пільга 100% (пп. 169.1.1 ПКУ)', value: 'privilege_169_1_1_100'},
                {text: 'Для осіб, які утрим. 2 та більше дітей 100% (пп. 169.1.2 ПКУ)', value: 'privilege_169_1_2_100'},
                {
                    text: 'Для одиноких матерів(батьків), опікунів 150% (пп. 169.1.3а ПКУ)',
                    value: 'privilege_169_1_3a_150'
                },
                {
                    text: 'Для осіб, I-II групи, які постраждали від Чорноб.катастрофи 150% (пп. 169.1.3б ПКУ)',
                    value: 'privilege_169_1_3b_150'
                },
                {
                    text: 'Для особи, яка є учнем, студентом, ординатором, ад’юктом 150% (пп. 169.1.3г ПКУ)',
                    value: 'privilege_169_1_3g_150'
                },
                {
                    text: 'Для особи, яка є інвалідами I-II групи, в т.ч з дитинства 150%  (пп. 169.1.3ґ ПКУ)',
                    value: 'privilege_169_1_3g1_150'
                },
                {
                    text: 'Для осіб, яким присуджено пожиттєву стипендію за переслід. 150%  (пп. 169.1.3д ПКУ)',
                    value: 'privilege_169_1_3d_150'
                },
                {
                    text: 'Для осіб, які є учас.бой. дій після Другої світової війни 150%  (пп. 169.1.3е ПКУ)',
                    value: 'privilege_169_1_3e_150'
                },
                {
                    text: 'Для осіб, які є героєм України, Рад.Союзу, Соціал.праці (200%)  (пп. 169.1.4а ПКУ)',
                    value: 'privilege_169_1_4a_200'
                },
                {
                    text: 'Для осіб, які є учас.бой дій під час Другої світової війни (200%)  (пп. 169.1.4б ПКУ)',
                    value: 'privilege_169_1_4b_200'
                },
                {
                    text: 'Для осіб, які є колиш. в’язнями концтаборів, гетто (200%)  (пп. 169.1.4в ПКУ)',
                    value: 'privilege_169_1_4v_200'
                },
                {
                    text: 'Для осіб, які буди примус. вивезені із територ. СРСР (200%)  (пп. 169.1.4г ПКУ)',
                    value: 'privilege_169_1_4g_200'
                },
                {
                    text: 'Для осіб, які перебували на блокадній терит. кол. Ленінграда(Санкт-Петербург) (200%)  (пп. 169.1.4ґ ПКУ)',
                    value: 'privilege_169_1_4g1_200'
                },
            ],
            sex_type_select,
            cripple_select: [
                {text: 'Перша група', value: 'first_category'},
                {text: 'Друга група', value: 'second_category'},
                {text: 'Третя група', value: 'third_category'}
            ],
            contract_pay_type_select: [
                {text: 'Щомісячно', value: 'every_month'},
                {text: 'В кінці періоду', value: 'end_of_period'},
            ],
            person_type_select,
            full_time_type_select,
            step: 0,
            chart_of_account: null,
            personalInfoValid: false,
            workPlaceValid: false,
            contractFormValid: false,
            otherFormValid: false,
            full_name: this.item.full_name,
            current_subdivision_id: this.item.current_subdivision_id || null,
            subdivision_name: this.item.subdivision_name || null,
            position_name: this.item.position_name || null,
            current_tab_number: this.item.current_tab_number || null,
            current_position_id: this.item.current_position_id || null,
            current_full_time_type: this.item.current_full_time_type || null,
            person_type: this.item.person_type || null,
            full_time_type: this.item.full_time_type || null,
            rate_count: 0,
            new_work_place: false,
            new_work_place_date: null,
            work_schedule_id: null,
            cripple_category: null,
            contract_date_start: null,
            contract_date_end: null,
            contract_pay_type: null,
            contract_sum: 0,
            social_privilege: null,
            hire_date: this.item.hire_date || null,
            release_date: this.item.release_date || null,
            is_released: this.item.is_released || false,
            pay_type: null,
            code: this.item.code || '',
            subdivision_id: null,
            tab_number: null,
            position_id: null,
            date_of_birth: this.item.date_of_birth || null,
            ipn: this.item.ipn || null,
            passport: this.item.passport || null,
            sex_type: null,
        }
    },
    methods: {
        clearPersonData(payload) {
            this.childs = []
            this.step = 0
            this.chart_of_account = null
            this.rate_count = 0
            this.new_work_place = false
            this.new_work_place_date = null
            this.work_schedule_id = null
            this.cripple_category = null
            this.contract_date_start = null
            this.contract_date_end = null
            this.contract_pay_type = null
            this.contract_sum = 0
            this.social_privilege = null
            this.pay_type = null
            this.subdivision_id = null
            this.tab_number = null
            this.position_id = null
            this.sex_type = null
            this.document_history = []
            if (payload) {
                this.current_full_time_type = payload.current_full_time_type
                this.current_subdivision_id = payload.current_subdivision_id
                this.current_position_id = payload.current_position_id
                this.current_tab_number = payload.current_tab_number
                this.person_type = payload.person_type
                this.hire_date = payload.hire_date
                this.release_date = payload.release_date
                this.is_released = payload.is_released
                this.itemId = payload.id
                this.position_name = payload.position_name
                this.subdivision_name = payload.subdivision_name
                this.ipn = payload.ipn
                this.full_time_type = payload.full_time_type
                this.passport = payload.passport
                this.date_of_birth = payload.date_of_birth
                this.full_name = payload.full_name
            } else {
                this.current_full_time_type = this.item.current_full_time_type
                this.current_subdivision_id = this.item.current_subdivision_id
                this.current_position_id = this.item.current_position_id
                this.current_tab_number = this.item.current_tab_number
                this.person_type = this.item.person_type
                this.hire_date = this.item.hire_date
                this.release_date = this.item.release_date
                this.is_released = this.item.is_released
                this.itemId = this.item.id
                this.position_name = this.item.position_name
                this.subdivision_name = this.item.subdivision_name
                this.ipn = this.item.ipn
                this.full_time_type = this.item.full_time_type
                this.passport = this.item.passport
                this.date_of_birth = this.item.date_of_birth
                this.full_name = this.item.full_name
            }
        },
        max_row() {
            let max_row_num = -1

            if (!this.childs) {
                return -1
            } else {
                this.childs.forEach(item => {
                    if ((item.row_num || 0) >= max_row_num) {
                        max_row_num = (item.row_num || 0)
                    }
                })
                return max_row_num
            }

        },
        add_child() {
            let max_row_num = this.max_row()
            if (!this.childs) this.childs = []
            this.childs.push(
                {
                    row_num: max_row_num + 1,
                    date_of_birth: null,
                    on_maintenance: false,
                }
            )
        },
        remove_child() {
            if (this.selected_row > -1) {
                const row = this.childs.find(item => item.row_num === this.selected_row)
                if (row) {
                    this.selected_row = -1
                    const row_idx = this.childs.indexOf(row)
                    this.childs.splice(row_idx, 1)
                }
            }
        },
        changeSelectRow(row_num) {
            if (this.selected_row === row_num) {
                this.selected_row = -1
            } else {
                this.selected_row = row_num
            }
        },
        nextStep(currentStep = 0) {
            if (currentStep === 0) {
                this.personalInfoValid = this.$refs.personalInfo.validate()
                if (!this.date_of_birth || !this.chart_of_account) {
                    this.personalInfoValid = false
                }
                if (!this.personalInfoValid) {
                    this.personalInfoValid = false
                    this.$store.commit(ALERT_SHOW, {
                        text: 'П.І.Б, Дата народження, Стать, Код за ДРФО - мають бути заповнені',
                        color: 'error lighten-1'
                    })
                } else {
                    if (this.person_type === 'full_time') {
                        this.step = 1
                    } else {
                        this.step = 2
                    }
                }
            }
            if (currentStep === 1) {
                this.workPlaceValid = this.$refs.workPlace.validate()
                if (!this.subdivision_id || !this.position_id || !this.work_schedule_id) {
                    this.workPlaceValid = false
                }
                if (!this.workPlaceValid) {
                    this.workPlaceValid = false
                    this.$store.commit(ALERT_SHOW, {
                        text: 'Підрозділ, посада, графік роботи та к-сть ставок - мають бути заповнені',
                        color: 'error lighten-1'
                    })
                } else {
                    this.step = 3
                }
            }
            if (currentStep === 2) {
                this.contractFormValid = this.$refs.contractForm.validate()
                if (!this.subdivision_id || !this.contract_date_start) {
                    this.contractFormValid = false
                }
                if (!this.contractFormValid) {
                    this.contractFormValid = false
                    this.$store.commit(ALERT_SHOW, {
                        text: 'Підрозділ, період з, вид оплати та сума договору - мають бути заповнені',
                        color: 'error lighten-1'
                    })
                } else {
                    this.createNewPerson()
                }
            }
            if (currentStep === 3) {
                this.createNewPerson()
            }
        },
        prevStep(currentStep) {
            if (currentStep === 1) {
                this.step = 0
            }
            if (currentStep === 2) {
                this.step = 0
            }
            if (currentStep === 3) {
                this.step = 1
            }
        },
        getDateOfBirth(payload) {
            if (!payload) return null
            if (payload.length < 10) return null
            const date = new Date('1899-12-31');
            const days = +payload.substr(0, 5) || 0
            const gender = +payload.substr(8, 1) || 0
            if (days <= 0) return
            date.setDate(date.getDate() + days)
            this.date_of_birth = format_date(date)
            if (gender > 0) {
                this.sex_type = gender % 2 === 0 ? 'female' : 'male'
            }
        },
        chartOfAccountChange(payload) {
            this.chart_of_account = (payload || {})?.value || null
        },
        subdivisionChange(payload) {
            this.subdivision_id = (payload || {})?.value || null
        },
        positionChange(payload) {
            this.position_id = (payload || {})?.value || null
        },
        workScheduleChange(payload) {
            this.work_schedule_id = (payload || {})?.value || null
        },
        fullNameChange(payload) {
            if (!payload) return
            const end_of_word = payload.substr(-3)
            if (end_of_word === 'вна') {
                this.sex_type = 'female'
            } else {
                this.sex_type = 'male'
            }
        },
        closeModal() {
            this.$emit('closeModal')
            this.clearPersonData()

            if (this.$refs.form) {
                this.$refs.form.resetValidation()
            }
            // this.$emit('closeModal')
        },
        deleteDialog() {
            this.menu = false
            const payload = {
                text: `Підтвердіть вилучення складу № ${this.short_name}`,
                accept_button: true,
                id: modalDeleteId
            }
            this.$store.dispatch(QUESTION_SHOW, payload)
        },
        newPersonPayload() {
            return {
                ipn: this.ipn,
                full_name: this.full_name,
                cripple_category: this.cripple_category,
                date_of_birth: this.date_of_birth,
                full_time_type: this.full_time_type,
                position_id: this.position_id,
                subdivision_id: this.subdivision_id,
                hire_date: this.hire_date,
                person_type: this.person_type,
                chart_of_account: this.chart_of_account,
                new_work_place: this.new_work_place,
                new_work_place_date: this.new_work_place_date,
                rate_count: this.rate_count,
                work_schedule_id: this.work_schedule_id,
                contract_pay_type: this.contract_pay_type,
                contract_date_start: this.contract_date_start,
                contract_date_end: this.contract_date_end,
                contract_sum: this.contract_sum,
                pay_type: this.pay_type,
                child: this.child,
                social_privilege: this.social_privilege,
                sex_type: this.sex_type
            }
        },
        createNewPerson() {
            this.$store.dispatch(CREATE_PERSONAL_LIST_ELEMENTS, this.newPersonPayload())
                .then(el => {
                    if (el) {
                        this.closeModal()
                    }
                })
        },
        crud_item() {
            this.formValid = this.$refs.form.validate()
            if (!this.formValid) {
                this.formValid = false
                this.$store.commit(ALERT_SHOW, {
                    text: 'П.І.Б, код за ДРФО та дата народження - мають бути заповнені',
                    color: 'error lighten-1'
                })
                return
            }

            const payload = {
                id: this.itemId,
                full_name: this.full_name,
                ipn: this.ipn,
                date_of_birth: this.date_of_birth,
                passport: this.item.passport
            }

            payload.id = this.itemId
            this.$store.dispatch(UPDATE_PERSONAL_LIST_ELEMENTS, payload)
                .then(el => {
                    if (el) {
                        this.closeModal()
                    }
                })
        },
        watch_item() {
            this.watcher = this.$watch(
                'item',
                {
                    deep: true,
                    immediate: true,
                    handler(payload) {
                        this.clearPersonData(payload)
                        if (payload.id) {
                            personalAPI.getHistory(payload.id)
                                .then(response => response.data)
                                .then(data => {
                                    this.document_history = data
                                })
                                .catch(err => {
                                    const error = err.response.data.detail;
                                    this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                                })
                        }
                    }
                }
            )
        },
        watch_dialog() {
            this.watcher = this.$watch(
                'dialog',
                {
                    immediate: true,
                    handler(payload) {
                        if (payload) {
                            this.watch_item()
                            this.watch_modal_answer()
                        } else {
                            if (this.watcher) {
                                this.watcher()
                            }
                            if (this.delete_watcher) {
                                this.delete_watcher()
                            }
                        }
                    }
                }
            )
        },
        watch_modal_answer() {
            this.delete_watcher = this.$watch(
                'modalAnswer',
                {
                    handler(payload) {
                        if (payload.id === modalDeleteId) {
                            if (payload.answer) {
                                this.$store.dispatch(REMOVE_PERSONAL_LIST_ELEMENTS, this.itemId)
                                    .then(ok => {
                                        if (ok) {
                                            this.closeModal()
                                        }
                                    })
                            }
                        }
                    }
                }
            )
        }
    },
    computed: {
        ...mapGetters({
            modalAnswer: 'question_answer'
        }),
    }
}
</script>

<style scoped lang="scss">
.custom-tabs.without {
  :deep(div[role='tablist']) {
    display: none;
  }
}

.form-title {
  color: #404040;
  font-size: .94rem;
  margin-top: 4px;
  padding-bottom: 5px;
  padding-top: 4px;
  padding-left: 2px;
  margin-right: 15px;
  border-bottom: 1px solid #ebebeb;
}

.table-input {
  :deep(input) {
    padding: 2px 8px !important;
    max-height: 26px !important;
  }
}

.table-col {
  padding: 3px 3px;
  font-size: 0.82rem;
}

.table-header {
  .table-col {
    padding: 5px 3px;
    background-color: #e3e3e3;
    font-weight: 500;
    font-size: 0.8rem;
  }
}

.table-body {
  .table-col {
    align-self: center;
  }
}
</style>